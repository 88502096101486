<template>
    <div class="bg-slightGrey minHeight" style="padding-bottom: 90px">
        <div class="width-100 paddingTb bg-white flex-row justify-content-start align-items-center">
            <img width="4px" height="24px" src="@/assets/image/icon1.png" alt="" />
            <p class="fs-big fw-mid black margin-l">施工信息</p>
        </div>
        <div class="width-90 paddingLr90 bg-white margin-bs">
            <p class="fs-mid grey margin-bs">施工单位</p>
            <p class="fs-mid black width-100 margin-b">{{ detail.submit_unit.name }}</p>
            <p class="fs-mid grey margin-bs">单位地址</p>
            <p class="fs-mid black width-100 margin-b">{{ detail.submit_unit.address }}</p>
            <p class="fs-mid grey margin-bs">单位联系人</p>
            <p class="fs-mid black width-100 margin-b">{{ detail.submit_unit.contact }}</p>
            <p class="fs-mid grey margin-bs">单位联系方式</p>
            <p class="fs-mid black width-100 margin-b">{{ detail.submit_unit.contact_phone }}</p>

            <template v-if="detail.master_unit">
                <p class="fs-mid grey margin-bs">业主单位</p>
                <p class="fs-mid black width-100 margin-b">{{ detail.master_unit.name }}</p>
                <p class="fs-mid grey margin-bs">业主地址</p>
                <p class="fs-mid black width-100 margin-b">{{ detail.master_unit.address }}</p>
                <p class="fs-mid grey margin-bs">业主联系人</p>
                <p class="fs-mid black width-100 margin-b">{{ detail.master_unit.contact }}</p>
                <p class="fs-mid grey margin-bs">业主联系方式</p>
                <p class="fs-mid black width-100 margin-b">{{ detail.master_unit.contact_phone }}</p>
            </template>

            <p class="fs-mid grey margin-bs">挖掘原因</p>
            <p class="fs-mid black width-100 margin-b">{{ detail.reason }}</p>
            <p class="fs-mid grey margin-bs">挖掘地段</p>
            <p class="fs-mid black width-100 margin-b">{{ detail.location }}</p>
            <p class="fs-mid grey margin-bs">挖掘时间</p>
            <p class="fs-mid black width-100 margin-b">{{ dateFormat(detail.start_date) }} 至 {{ dateFormat(detail.complete_date) }}</p>
            <p class="fs-mid grey margin-bs">挖掘面积</p>
            <p class="fs-mid black width-100 margin-b">{{ detail.acreage }}</p>
            <p class="fs-mid grey margin-bs">路面性质</p>
            <p class="fs-mid black width-100 margin-b">{{ detail.pavement_nature }}</p>
            <p class="fs-mid grey margin-bs">负责的交警/协警</p>
            <p class="fs-mid black width-100 margin-b">{{ detail.rematch_user }}</p>
            <p v-if="detail.status !== '申请中'" class="fs-mid grey margin-bs">巡查照片</p>

            <div v-if="detail.status !== '申请中'" class="flex-row flex-wrap justify-content-start align-items-center margin-ts">
                <van-image
                    v-for="(image, index) in detail.images"
                    @click="preview(detail.images, index)"
                    :key="index"
                    :src="mediaUrl + image.src"
                    width="80"
                    height="80"
                    fit="cover"
                    class="margin-r margin-bs"
                />
                <van-image-preview v-model="previewShow" :images="previewImages" :start-position="previewIndex"> </van-image-preview>
            </div>
        </div>
        <div v-if="detail.status !== '申请中'" class="width-100 paddingTb bg-white flex-row justify-content-start align-items-center">
            <img width="4px" height="24px" src="@/assets/image/icon1.png" alt="" />
            <p class="fs-big fw-mid black margin-l">巡查记录</p>
        </div>
        <div class="width-90 paddingLr90 bg-white margin-bs">
            <!--      记录列表start-->
            <div
                v-for="rematch in detail.rematches"
                :key="rematch.id"
                @click="jumpto('/xc', { id: rematch.id })"
                class="width-100 paddingTb border-b flex-row justify-content-spaceBetween align-items-star"
            >
                <div class="width-60 flex-row justify-content-start align-items-star">
                    <img width="24px" height="24px" src="@/assets/image/icon14.png" alt="" />
                    <div class="">
                        <p class="fs-big black lineHeight margin-l">
                            {{ rematch.is_complete ? '施工完成' : rematch.is_well ? '施工正常' : '施工异常' }}
                        </p>
                        <p class="fs-sml darkgrey margin-l pre-line">{{ rematch.work_status }}</p>
                    </div>
                </div>
                <p class="fs-sml darkgrey width-40 textAlign-r">{{ datetimeFormat(rematch.match_time) }}</p>
            </div>
            <!--      记录列表start-->
        </div>
        <div v-if="detail.status === '施工中' && detail.rematch_user_id === ddUserId" class="width-90 padding90 bg-white van-tabbar--fixed">
            <van-button type="primary" block color="#007AFF" @click="jumpto('/form', { id: detail.id })">上报巡查记录</van-button>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: '',
    components: {},
    data() {
        return {
            ddUserId: null,

            images: [],
            list: [],
            detail: {
                submit_unit: {
                    name: '',
                    address: '',
                    contact: '',
                    contact_phone: ''
                },
                master_unit: {
                    name: '',
                    address: '',
                    contact: '',
                    contact_phone: ''
                }
            },
            previewShow: false,
            previewImages: [],
            previewIndex: 0
        }
    },
    created() {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        this.ddUserId = userInfo.dd_user_id

        const { id } = this.$route.query
        axios
            .get(`/api/supervision/project_detail/${id}/`)
            .then((res) => {
                this.detail = res.data
                this.detail.id = id
            })
            .catch(() => {
                this.$dialog.alert({
                    message: '无法获取数据，请检查网络后重新打开。'
                })
            })
    },
    mounted() {},
    methods: {
        preview(images, startIndex) {
            this.previewShow = true
            this.previewImages = images.map((image) => this.mediaUrl + image.src)
            this.previewIndex = startIndex
        }
    }
}
</script>

<style lang="less" scoped></style>
